import { Component, OnInit, Input, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import * as _ from "lodash";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  moduleId: module.id,
  selector: "app-paginator",
  templateUrl: "./paginator.component.html",
  styleUrls: ["./paginator.component.css"],
})
export class PaginatorComponent {
  config: any;
  public pagingChange: EventEmitter<any>;
  private timeout = null;

  @Input() search;

  constructor(private router: Router, private route: ActivatedRoute) {
    const page = +this.route.snapshot.queryParams["page"];
    const take = +this.route.snapshot.queryParams["take"];
    const search = this.route.snapshot.queryParams["search"];

    this.config = {
      page: page || 1,
      take: take || 25,
      sizes: [10, 25, 50, 100],
      totalItems: 0,
      pages: 0,
      search: search || null,
    };

    this.pagingChange = new EventEmitter();
  }

  /*   constructor(private router: Router, private route: ActivatedRoute) {
    let firstTime = true;
    this.route.queryParams.subscribe((qp) => {
      if (!firstTime) {
        return;
      }
      this.config = {
        page: +qp["page"] || 1,
        take: +qp["take"] || 25,
        sizes: [10, 25, 50, 100],
        totalItems: 0,
        pages: 0,
        search: null,
      };

      this.pagingChange = new EventEmitter();
      if (this.config.page > 1) {
        this.pagingChange.emit(this.config);
      }
      firstTime = false;
    });
  } */

  changeSearch() {
    clearTimeout(this.timeout);
    // Make a new timeout set to go off in X ms
    this.timeout = setTimeout(() => {
      this.config.page = 1;

      let qp = {};
      Object.assign(qp, this.route.snapshot.queryParams);
      Object.assign(qp, {
        page: this.config.page,
        take: this.config.take,
        search: this.config.search,
      });
      this.router.navigate(["."], {
        relativeTo: this.route,
        queryParams: qp,
      });
      this.pagingChange.emit(this.config);
    }, 500);
  }

  reset() {
    this.config.page = 1;
  }

  setTotalItems(size) {
    this.config.totalItems = size;
    this.config.pages = Math.ceil(size / this.config.take);
  }

  goToStart() {
    this.config.page = 1;
    this.pagingChange.emit(this.config);
  }

  goToEnd() {
    this.config.page = this.config.pages;
    this.pagingChange.emit(this.config);
  }

  setPage(page) {
    let qp = {};
    Object.assign(qp, this.route.snapshot.queryParams);
    Object.assign(qp, {
      page: page,
      take: this.config.take,
      search: this.config.search,
    });

    this.config.page = page;
    this.getNumber(this.config.pages);
    this.pagingChange.emit(this.config);
    this.router.navigate(["."], {
      relativeTo: this.route,
      queryParams: qp,
    });
  }

  changeSize() {
    this.config.page = 1;
    this.pagingChange.emit(this.config);
    this.router.navigate(["."], {
      relativeTo: this.route,
      queryParams: {
        page: this.config.page,
        take: this.config.take,
      },
    });
  }

  getNumber(number) {
    let start = 0;
    if (number > 9) {
      if (this.config.page > 5) {
        start = this.config.page - 5;
      }
      if (this.config.page > number - 5) {
        start = number - 9;
      }
      number = 9;
    }
    const numbers = [];
    for (let i = start; i < number + start; i++) {
      numbers.push(i + 1);
    }
    return numbers;
  }
}
