import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // this is needed!
import { RouterModule } from "@angular/router";
import { HttpModule } from "@angular/http";
import { APP_BASE_HREF } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { Ng2CompleterModule } from "ng2-completer";

import { AppComponent } from "./app.component";
import { Configuration } from "./app.constants";

// import { SidebarModule } from './sidebar/sidebar.module';
import { FixedPluginModule } from "./shared/fixedplugin/fixedplugin.module";
import { PaginatorModule } from "./shared/paginator/paginator.module";
import { PagesnavbarModule } from "./shared/pagesnavbar/pagesnavbar.module";
import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { AppRoutes } from "./app.routing";

import { CmsLabelService } from "./shared/services/cms_label";
import { SlideshowService } from "./shared/services/slideshow";
import { NotificationService } from "./shared/services/notifcation";
import { LanguageService } from "./shared/services/language";
import { TranslationService } from "./shared/services/translation";
import { CategoryService } from "./shared/services/category";
import { ProductService } from "./shared/services/product";
import { ProductSetService } from "./shared/services/product_set";
import { VariationService } from "./shared/services/variation";
import { UseTypeService } from "./shared/services/use_type";
import { UserService } from "./shared/services/user";
import { ShippingCostService } from "./shared/services/shipping_cost";
import { VatSystemService } from "./shared/services/vat_system";
import { BrandService } from "./shared/services/brand";
import { SettingService } from "./shared/services/setting";
import { MeasureUnitService } from "./shared/services/measure_unit";
import { CountryService } from "./shared/services/country";
import { TutorialService } from "./shared/services/tutorial";
import { GlobalService } from "./shared/services/global";
import { FaqService } from "./shared/services/faq";
import { ImgService } from "./shared/services/img";
import { OrderService } from "./shared/services/order";
import { InvoiceService } from "./shared/services/invoice";
import { BalanceService } from "./shared/services/balance";
import { AuthService } from "./shared/services/auth";
import { CouponService } from "./shared/services/coupon";
import { NotifyService } from "./shared/services/notify";
import { GiftService } from "./shared/services/gift";

import { CKEditorModule } from "ng2-ckeditor";

import { MainPipeModule } from "./shared/pipes/pipes.module";
import { AuthInterceptor } from "./shared/interceptor/auth.interceptor";
import { ShippingZoneService } from "./shared/services/shipping_zone";
import { CarrierService } from "./shared/services/carrier";
import { GeneralSettingService } from "./shared/services/general_setting";
import { ProvService } from "./shared/services/prov";
import { DiscountService } from "./shared/services/discount";
import { StatisticsService } from "./shared/services/statistics";
import {
  NgxChartsModule,
  PieChartModule,
  BarChartModule,
  LineChartModule,
  ChartCommonModule,
} from "@swimlane/ngx-charts";
import { ComboChartComponent } from "./shared/combo-chart/combo-chart.component";
import { ComboChartModule } from "./shared/combo-chart/combo-chart.module";
import { SurveyService } from "./shared/services/survey";
import { PlaylistService } from "./shared/services/playlist";

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes),
    HttpModule,
    HttpClientModule,
    CKEditorModule,
    FixedPluginModule,
    Ng2CompleterModule,
    PagesnavbarModule,
    PaginatorModule,
    MainPipeModule,
    NgxChartsModule,
    PieChartModule,
    LineChartModule,
    ChartCommonModule,
    ComboChartModule,
  ],
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    Configuration,
    CmsLabelService,
    ShippingZoneService,
    CarrierService,
    CategoryService,
    UseTypeService,
    BalanceService,
    GeneralSettingService,
    SurveyService,
    CouponService,
    DiscountService,
    ProductService,
    GlobalService,
    VariationService,
    TutorialService,
    VatSystemService,
    ProvService,
    StatisticsService,
    ProductSetService,
    ShippingCostService,
    FaqService,
    ImgService,
    UserService,
    OrderService,
    SlideshowService,
    AuthService,
    InvoiceService,
    SettingService,
    GiftService,
    PlaylistService,
    MeasureUnitService,
    CountryService,
    BrandService,
    NotifyService,
    LanguageService,
    TranslationService,
    NotificationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
