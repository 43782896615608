import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Configuration } from "../../app.constants";
import { ServiceBase } from "./base/base.service";
import { Observable, Subject } from "rxjs";

@Injectable()
export class UserService extends ServiceBase {
  constructor(http: HttpClient, _configuration: Configuration) {
    super("user", http, _configuration);
  }
  public downloadCSV<T>(queryString): Observable<T> {
    return this.http.get<T>(`${this.actionUrl}${this.basePath}/csv`);
  }
}
