import { IService } from '../interface/base/iservice';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Configuration } from 'app/app.constants';
import { Observable } from 'rxjs';
import { QueryStringBuilder } from '../../utils/queryString';

export class ServiceBase implements IService {
  actionUrl: string;
  http: HttpClient;
  basePath: string;

  constructor(
    basePath: string,
    http: HttpClient,
    _configuration: Configuration
  ) {
    this.actionUrl = _configuration.apiBase;
    this.http = http;
    this.basePath = basePath;
  }
  filter<T>(queryString): Observable<T> {
    const myParams = queryString
      ? QueryStringBuilder.BuildParametersFromSearch<T>(queryString)
      : null;
    return this.http.get<T>(`${this.actionUrl}${this.basePath}/filter`, {
      params: myParams,
    });
  }
  get<T>(id: number): Observable<T> {
    return this.http.get<T>(`${this.actionUrl}${this.basePath}/${id}`);
  }
  getAll<T>(filter?: any): Observable<T> {
    return this.http.get<T>(`${this.actionUrl}${this.basePath}`);
  }
  create<T>(model: any): Observable<T> {
    return this.http.post<T>(`${this.actionUrl}${this.basePath}`, model);
  }
  update<T>(id: number, model: any): Observable<T> {
    return this.http.put<T>(`${this.actionUrl}${this.basePath}/${id}`, model);
  }
  delete<T>(id: number): Observable<T> {
    return this.http.delete<T>(`${this.actionUrl}${this.basePath}/${id}`);
  }
}
