import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { map, filter, scan } from 'rxjs/operators';


import { LocationStrategy, PlatformLocation, Location } from '@angular/common';

import { PagesnavbarComponent } from '../../shared/pagesnavbar/pagesnavbar.component';

declare var $: any;

@Component({
  selector: 'app-layout',
  templateUrl: './auth-layout.component.html'
})

export class AuthLayoutComponent implements OnInit {
  location: Location;
  private _router: Subscription;
  // url: string;

  @ViewChild(PagesnavbarComponent) pagesnavbar: PagesnavbarComponent;
  constructor(private router: Router, location: Location) {
    this.location = location;
  }

  ngOnInit() {
    this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      //   this.url = event.url;
      this.pagesnavbar.sidebarClose();
    });
  }
}
