import { Routes } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "/order/list",
    pathMatch: "full"
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "notify",
        loadChildren: "./notify/notify.module#NotifyModule"
      },
      {
        path: "translations",
        loadChildren: "./translations/translations.module#TranslationsModule"
      },
      {
        path: "countries",
        loadChildren: "./countries/countries.module#CountriesModule"
      },
      {
        path: "slideshow",
        loadChildren: "./slideshow/slideshow.module#SlideshowModule"
      },
      {
        path: "brand",
        loadChildren: "./brand/brand.module#BrandModule"
      },
      {
        path: "faq",
        loadChildren: "./faq/faq.module#FaqModule"
      },
      {
        path: "cart",
        loadChildren: "./cart/cart.module#CartModule"
      },
      {
        path: "order",
        loadChildren: "./order/order.module#OrderModule"
      },
      {
        path: "product",
        loadChildren: "./product/product.module#ProductModule"
      },
      {
        path: "product_set",
        loadChildren: "./product_set/product-set.module#ProductSetModule"
      },
      {
        path: "usetype",
        loadChildren: "./use_types/use-type.module#UseTypesModule"
      },
      {
        path: "setting",
        loadChildren: "./setting/setting.module#SettingModule"
      },
      {
        path: "user",
        loadChildren: "./user/user.module#UserModule"
      },
      {
        path: "coupon",
        loadChildren: "./coupon/coupon.module#CouponModule"
      },
      {
        path: "discount",
        loadChildren: "./discount/discount.module#DiscountModule"
      },
      {
        path: "shipping",
        loadChildren: "./shipping/shipping.module#ShippingModule"
      },
      {
        path: "tutorial",
        loadChildren: "./tutorial/tutorial.module#TutorialModule"
      },
      {
        path: "components",
        loadChildren: "./components/components.module#ComponentsModule"
      },
      {
        path: "category",
        loadChildren: "./categories/categories.module#CategoriesModule"
      },
      {
        path: "playlist",
        loadChildren: "./playlist/playlist.module#PlaylistModule"
      },

      {
        path: "components",
        loadChildren: "./components/components.module#ComponentsModule"
      },
      {
        path: "tables",
        loadChildren: "./tables/tables.module#TablesModule"
      },
      {
        path: "maps",
        loadChildren: "./maps/maps.module#MapsModule"
      },
      {
        path: "charts",
        loadChildren: "./charts/charts.module#ChartsModule"
      },
      {
        path: "calendar",
        loadChildren: "./calendar/calendar.module#CalendarModule"
      },
      {
        path: "survey",
        loadChildren: "./survey/survey.module#SurveyModule"
      },
      {
        path: "dashboard",
        loadChildren: "./dashboard/dashboard.module#DashboardModule"
      },
      {
        path: "",
        loadChildren: "./userpage/user.module#UserModule"
      }
    ]
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "pages",
        loadChildren: "./pages/pages.module#PagesModule"
      }
    ]
  }
];
