import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { BarChartModule, PieChartModule, ChartCommonModule, LineChartModule, NgxChartsModule } from '@swimlane/ngx-charts';
import { ComboChartComponent } from './combo-chart.component';
import { ComboSeriesVerticalComponent } from './combo-series-vertical.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxChartsModule,
        PieChartModule,
        BarChartModule,
        LineChartModule,
        ChartCommonModule
    ],
    declarations: [
        ComboChartComponent,
        ComboSeriesVerticalComponent
    ],
    exports: [
        ComboChartComponent,
        ComboSeriesVerticalComponent
    ]
})

export class ComboChartModule { }
