import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '../../app.constants';
import { ServiceBase } from './base/base.service';
import { QueryStringBuilder } from '../utils/queryString';
import * as moment from 'moment';

@Injectable()
export class StatisticsService extends ServiceBase {
    constructor(http: HttpClient, _configuration: Configuration) {
        super('statistics', http, _configuration)
    }
    private _getSellByParam<T>(param, isNew, from, to) {
        const oldOrNew = isNew ? 'new' : 'old';
        const myParams = QueryStringBuilder.BuildParametersFromSearch({
            from: moment(from).format('YYYY-MM-DD'),
            to: moment(to).format('YYYY-MM-DD'),
            oldOrNew
        });

        return this.http.get<T>(`${this.actionUrl}${this.basePath}/${param}`, { params: myParams });
    }
    public getSellByCategory<T>(isNew, from, to) {
        return this._getSellByParam('sellByCategory', isNew, from, to);
    }
    public getSellByBrand<T>(isNew, from, to) {
        return this._getSellByParam('sellByBrand', isNew, from, to);
    }
    public getSellByUseType<T>(isNew, from, to) {
        return this._getSellByParam('sellByUseType', isNew, from, to);
    }
    public getSellByLanguage<T>(isNew, from, to) {
        return this._getSellByParam('sellByLanguage', isNew, from, to);
    }
    public getSellByInvoiceType<T>(isNew, from, to) {
        return this._getSellByParam('sellByInvoiceType', isNew, from, to);
    }
    public getSellByPaymentType<T>(isNew, from, to) {
        return this._getSellByParam('sellByPaymentType', isNew, from, to);
    }
    public getSellByRegisteredUser<T>(isNew, from, to) {
        return this._getSellByParam('sellByRegisteredUser', isNew, from, to);
    }
    public getSellByWeightRanges<T>(isNew, from, to) {
        return this._getSellByParam('sellByWeightRanges', isNew, from, to);
    }
    public getSellByCarrier<T>(isNew, from, to) {
        return this._getSellByParam('sellByCarrier', isNew, from, to);
    }
    public getProductsSoldOverShipping<T>(isNew, from, to) {
        return this._getSellByParam('productsSoldOverShipping', isNew, from, to);
    }
    public getUserByLanguage<T>(isNew, from, to) {
        return this._getSellByParam('userByLanguage', isNew, from, to);
    }
    public getUserByCountry<T>(isNew, from, to) {
        return this._getSellByParam('userByCountry', isNew, from, to);
    }
    public getUserByRegisterType<T>(isNew, from, to) {
        return this._getSellByParam('userByRegisterType', isNew, from, to);
    }
    public getUserByNewsletter<T>(isNew, from, to) {
        return this._getSellByParam('userByNewsletter', isNew, from, to);
    }
    public getSellByCountry<T>(isNew, from, to) {
        return this._getSellByParam('sellByCountry', isNew, from, to);
    }
    public getSellAddresses<T>(isNew, from, to) {
        return this._getSellByParam('sellAddresses', isNew, from, to);
    }
    public getSellGeneral<T>(isNew, from, to) {
        return this._getSellByParam('sellGeneral', isNew, from, to);
    }
    public getSellByWeek<T>(isNew, from, to) {
        return this._getSellByParam('sellByWeek', isNew, from, to);
    }
    public getSellOnlySample<T>(isNew, from, to) {
        return this._getSellByParam('sellOnlySample', isNew, from, to);
    }
    public getSampleConversion<T>(isNew, from, to) {
        return this._getSellByParam('sampleConversion', isNew, from, to);
    }
    public getShippedWeight<T>(isNew, from, to) {
        return this._getSellByParam('shippedWeight', isNew, from, to);
    }
}
