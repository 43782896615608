

import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '../../app.constants';
import { ServiceBase } from './base/base.service';

@Injectable()
export class BalanceService extends ServiceBase {
    constructor(http: HttpClient, _configuration: Configuration) {
        super('balance', http, _configuration)
    }
}
