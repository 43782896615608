import { Observable, Observer } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable()
export class GlobalService {
    ita_id: any;
    eng_id: any;
    fra_id: any;
    dataChange: Observable<any>;
    dataChangeObserver;
    constructor() {
        this.ita_id = 70;
        this.eng_id = 40;
        this.fra_id = 47;
        this.dataChange = new Observable((observer: Observer<any>) => {
            this.dataChangeObserver = observer;
        });
    }

    setItaId(ita_id: any) {
        this.ita_id = ita_id;
        this.dataChangeObserver.next(this.ita_id);
    }
    setEngId(eng_id: any) {
        this.eng_id = eng_id;
        this.dataChangeObserver.next(this.eng_id);
    }
    setFraId(fra_id: any) {
        this.fra_id = fra_id;
        this.dataChangeObserver.next(this.fra_id);
    }
}
