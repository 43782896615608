import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "../../app.constants";
import { ServiceBase } from "./base/base.service";
import { QueryStringBuilder } from "../utils/queryString";
import * as moment from "moment";

@Injectable()
export class SurveyService extends ServiceBase {
  public currentQuestion: any = {};
  constructor(http: HttpClient, _configuration: Configuration) {
    super("survey", http, _configuration);
  }
  public getAllVotes<T>() {
    return this.http.get<T>(`${this.actionUrl}${this.basePath}/allVotes`);
  }
  public getAllMulti<T>() {
    return this.http.get<T>(`${this.actionUrl}${this.basePath}/allMulti`);
  }
  public getAll<T>() {
    return this.http.get<T>(`${this.actionUrl}${this.basePath}`);
  }
  public getAllSurveyOrders<T>(queryString) {
    const myParams = queryString
      ? QueryStringBuilder.BuildParametersFromSearch<T>(queryString)
      : null;
    return this.http.get<T>(`${this.actionUrl}${this.basePath}/orders`, {
      params: myParams,
    });
  }
  public getSurveyOrderDetail<T>(id: number) {
    return this.http.get<T>(`${this.actionUrl}${this.basePath}/orders/${id}`);
  }
  public getQuestion<T>(id: number) {
    return this.http.get<T>(`${this.actionUrl}${this.basePath}/question/${id}`);
  }
}
