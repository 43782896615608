

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '../../app.constants';
import { ServiceBase } from './base/base.service';

@Injectable()
export class FaqService extends ServiceBase {

    private actionUrlCategory: string;

    constructor(http: HttpClient, _configuration: Configuration) {
        super('faq', http, _configuration)
        this.actionUrlCategory = _configuration.apiBase + 'faq_category/';
    }

    public getAllCategories<T>(): Observable<T> {
        return this.http.get<T>(this.actionUrlCategory);
    }
    public getCategory<T>(id: number): Observable<T> {
        return this.http.get<T>(this.actionUrlCategory + id);
    }
    public createCategory<T>(model: any): Observable<T> {
        return this.http.post<T>(this.actionUrlCategory, model);
    }
}
