

import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { CompleterData, CompleterItem } from 'ng2-completer';

import { Configuration } from '../../app.constants';

@Injectable()
export class ProvService extends Subject<CompleterItem[]> implements CompleterData {

    private actionUrl: string;

    constructor(private http: HttpClient, private _configuration: Configuration) {
        super();
        this.actionUrl = _configuration.apiBase + 'prov/';
    }

    public getAll<T>(qs?: string): Observable<T> {
        return this.http.get<T>(this.actionUrl + (qs || ''));
    }
    public update<T>(id: number, model: any): Observable<T> {
        return this.http.put<T>(this.actionUrl + id, model);
    }

    public search(term: string): void {
        this.http.get(`${this.actionUrl}filter?search=${term}`)
            .map((res: any) => {
                const matches: CompleterItem[] = res.map((row: any) => this.convertToItem(row));
                this.next(matches);
            })
            .subscribe();
    }

    public convertToItem(data: any): CompleterItem | null {
        if (!data) {
            return null;
        }
        // data will be string if an initial value is set
        return {
            title: data.name.Translations[0].text + (data.ShippingZone ? ` (${data.ShippingZone.name})` : ''),
            // description: data.sku,
            // image: 'https://s3.eu-central-1.amazonaws.com/fenceshop/product/primary/lq/' + data.Imgs[0].I18n.Translations[0].text,
            originalObject: data
        } as CompleterItem;
    }

    public cancel() {
        // Handle cancel
    }
}
