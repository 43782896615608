import { Injectable } from '@angular/core';
import { environment } from 'environments/environment'

@Injectable()
export class Configuration {
    public Server = environment.Server;
    public ApiUrl = environment.ApiUrl;
    public apiBase = environment.apiBase;
    public clientApiBase = environment.clientApiBase;
}
