

import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { QueryStringBuilder } from '../utils/queryString';
import { CompleterData, CompleterItem } from 'ng2-completer';

import { Configuration } from '../../app.constants';

@Injectable()
export class ProductService extends Subject<CompleterItem[]> implements CompleterData {

    private actionUrl: string;

    constructor(private http: HttpClient, private _configuration: Configuration) {
        super();
        this.actionUrl = _configuration.apiBase + 'product/';
    }

    public getAll<T>(queryString): Observable<T> {
        const myParams = queryString ?
            QueryStringBuilder.BuildParametersFromSearch<T>(queryString) :
            null;

        return this.http.get<T>(this.actionUrl, { params: myParams });
    }
    public get<T>(id: number): Observable<T> {
        return this.http.get<T>(this.actionUrl + id);
    }
    public getPrevNext<T>(id: number): Observable<T> {
        return this.http.get<T>(this.actionUrl + id + '/prev_next');
    }
    public getByPos<T>(pos: number): Observable<T> {
        return this.http.get<T>(this.actionUrl + 'pos/' + pos);
    }
    public getMinMaxPosition<T>(): Observable<T> {
        return this.http.get<T>(this.actionUrl + 'minmax');
    }
    public create<T>(model: any): Observable<T> {
        return this.http.post<T>(this.actionUrl, model);
    }
    public update<T>(id: number, model: any): Observable<T> {
        return this.http
            .put<T>(this.actionUrl + id, model);
    }
    public updatePos<T>(id: number, pos: number): Observable<T> {
        return this.http
            .put<T>(this.actionUrl + id + '/pos/' + pos, {});
    }
    public addRelated<T>(id: number, id_related: number): Observable<T> {
        return this.http
            .put<T>(this.actionUrl + id + '/related/' + id_related, {});
    }
    public removeRelated<T>(id: number, id_related: number): Observable<T> {
        return this.http
            .delete<T>(this.actionUrl + id + '/related/' + id_related);
    }
    public delete<T>(id: number): Observable<T> {
        return this.http.delete<T>(this.actionUrl + id);
    }
    public uploadImg<T>(id, file) {
        const formData: any = new FormData();
        formData.append('uploads[]', file, file['name']);

        return this.http.post<T>(this.actionUrl + id + '/img', formData);
    }
    public uploadPdf<T>(id, file) {
        const formData: any = new FormData();
        formData.append('uploads[]', file, file['name']);

        return this.http.post<T>(this.actionUrl + id + '/pdf', formData);
    }
    public uploadSlider<T>(id, file) {
        const formData: any = new FormData();
        formData.append('uploads[]', file, file['name']);

        return this.http.post<T>(this.actionUrl + id + '/slider', formData);
    }
    public search(term: string): void {
        this.http.get(`${this.actionUrl}?page=1&take=10&search=${term}`)
            .map((res: any) => {
                const matches: CompleterItem[] = res.rows.map((row: any) => this.convertToItem(row));
                this.next(matches);
            })
            .subscribe();
    }

    public cancel() {
        // Handle cancel
    }

    public convertToItem(data: any): CompleterItem | null {
        if (!data) {
            return null;
        }
        // data will be string if an initial value is set
        return {
            title: data.name.Translations[0].text,
            // description: data.sku,
            // image: 'https://s3.eu-central-1.amazonaws.com/fenceshop/product/primary/lq/' + data.Imgs[0].I18n.Translations[0].text,
            originalObject: data
        } as CompleterItem;
    }
}


@Injectable()
export class CustomInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.headers.has('Content-Type')) {
            req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        }

        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
        console.log(JSON.stringify(req.headers));
        return next.handle(req);
    }
}
