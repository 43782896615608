import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PaginatorComponent } from './paginator.component';

@NgModule({
    imports: [FormsModule, CommonModule],
    declarations: [PaginatorComponent],
    exports: [PaginatorComponent]
})

export class PaginatorModule {

}
