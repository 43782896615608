import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '../../app.constants';
import { ServiceBase } from './base/base.service';

@Injectable()
export class SlideshowService extends ServiceBase {
    constructor(http: HttpClient, _configuration: Configuration) {
        super('slideshow', http, _configuration)
    }
    public uploadImg<T>(id, file) {
        const formData: any = new FormData();
        formData.append('uploads[]', file, file['name']);

        return this.http.post<T>(`${this.actionUrl}${this.basePath}/${id}/img`, formData);
    }
}
