

import { Injectable } from '@angular/core';
declare var $: any;
declare var swal: any;

@Injectable()
export class NotificationService {
    constructor() {
    }
    public deleteWarning(title?, message?, cb?) {
        swal({
            title: title || 'Sicuro di voler procedere?',
            text: message || 'Questa azione non è reversibile!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonClass: 'btn btn-success btn-fill',
            confirmButtonText: 'Conferma',
            cancelButtonClass: 'btn btn-danger btn-fill',
            cancelButtonText: 'Annulla',
            closeOnConfirm: true,
        }, () => {
            if (cb) {
                return cb(true);
            }
        }, () => {
            if (cb) {
                return cb(false);
            }
        });
    }
    public alertSuccess(message, cb?) {
        swal(
            {
                // title: 'Ok',
                title: message,
                timer: 2000
                // type: 'success'
            }, cb
        )
    }
    public alertError(message, cb?) {
        swal(
            {
                title: 'Errore',
                text: message,
                type: 'error'
            }, cb
        )
    }
    public showError(message) {
        $.notify({
            // icon: 'fa fa-exclamation-circle',
            message: message || 'Si è verificato un errore'

        }, {
                type: 'alert alert-danger',
                delay: 4000,
                placement: {
                    from: 'top',
                    align: 'right'
                }
            });

    }
}
