import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../../app.constants';
import { ServiceBase } from './base/base.service';

@Injectable()
export class ProductSetService extends ServiceBase {
    constructor(http: HttpClient, _configuration: Configuration) {
        super('product_set', http, _configuration)
    }
    public getByPos<T>(pos: number): Observable<T> {
        return this.http.get<T>(`${this.actionUrl}${this.basePath}/pos/${pos}`);
    }
}
