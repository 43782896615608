import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../../app.constants';
import { ServiceBase } from './base/base.service';
import { QueryStringBuilder } from '../utils/queryString';

@Injectable()
export class TutorialService extends ServiceBase {
  constructor(http: HttpClient, _configuration: Configuration) {
    super('tutorial', http, _configuration);
  }
  public getAll<T>(queryString): Observable<T> {
    const myParams = queryString
      ? QueryStringBuilder.BuildParametersFromSearch<T>(queryString)
      : null;

    return this.http.get<T>(`${this.actionUrl}${this.basePath}`, {
      params: myParams,
    });
  }
  public addSlide<T>(tutorial_id, model: any): Observable<T> {
    return this.http.post<T>(
      `${this.actionUrl}${this.basePath}/${tutorial_id}/slide`,
      model
    );
  }
  public addRelated<T>(
    id_tutorial: number,
    id_product_related: number
  ): Observable<T> {
    return this.http.put<T>(
      `${this.actionUrl}${this.basePath}/${id_tutorial}/related/${id_product_related}`,
      {}
    );
  }
  public removeRelated<T>(
    id: number,
    id_product_related: number
  ): Observable<T> {
    return this.http.delete<T>(
      `${this.actionUrl}${this.basePath}/${id}/related/${id_product_related}`
    );
  }
  public uploadImg<T>(id, file) {
    const formData: any = new FormData();
    formData.append('uploads[]', file, file['name']);

    return this.http.post<T>(
      `${this.actionUrl}${this.basePath}/${id}/img`,
      formData
    );
  }
  public uploadSlider<T>(id, slide_id, file) {
    const formData: any = new FormData();
    formData.append('uploads[]', file, file['name']);

    return this.http.post<T>(
      `${this.actionUrl}${this.basePath}/${id}/slider/${slide_id}/img`,
      formData
    );
  }
  public updateSlide<T>(
    id: number,
    id_slide: number,
    model: any
  ): Observable<T> {
    return this.http.put<T>(this.actionUrl + id + '/slide/' + id_slide, model);
  }
  public deleteSlide<T>(id: number, id_slide: number): Observable<T> {
    return this.http.delete<T>(
      `${this.actionUrl}${this.basePath}/${id}/slide/${id_slide}`
    );
  }
}
