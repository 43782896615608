import { Component, OnInit, Renderer, ViewChild, ElementRef, Directive } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AuthService } from '../services/auth';
import { Configuration } from '../../app.constants';

declare var $: any;

@Component({
    moduleId: module.id,
    selector: 'app-pagesnavbar-cmp',
    templateUrl: 'pagesnavbar.component.html'
})

export class PagesnavbarComponent implements OnInit {
    location: Location;
    private nativeElement: Node;
    private toggleButton;
    private sidebarVisible: boolean;

    @ViewChild('app-pagesnavbar-cmp') button;

    constructor(
        location: Location,
        private renderer: Renderer,
        private element: ElementRef,
        private _authService: AuthService,
        private _config: Configuration) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        console.log(this.location.prepareExternalUrl(this.location.path()));

        $('.nav .dropdown .dropdown-toggle, .dropdown-menu').hover(function () {
            $(this).parent().find('.dropdown-menu').css({
                visibility: 'visible',
                opacity: '1',
                'margin-top': '-10px'
            });
        }, function () {
            $(this).parent().find('.dropdown-menu').attr('style', '');
        });

    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');
        this.sidebarVisible = true;
    }
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    }
    sidebarToggle() {
        // var toggleButton = this.toggleButton;
        // var body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    isLogin() {
        if (this.location.prepareExternalUrl(this.location.path()) === 'pages/login') {
            return true;
        }
        return false;
    }

    logout() {
        this._authService.logout().subscribe(res => {
            window.location.href = this._config.Server + 'fs-admin/login';
        });
    }

    isLock() {
        if (this.location.prepareExternalUrl(this.location.path()) === 'pages/lock') {
            return true;
        }
        return false;
    }

    isRegister() {
        if (this.location.prepareExternalUrl(this.location.path()) === 'pages/register') {
            return true;
        }
        return false;
    }

    getPath() {
        // console.log(this.location);
        return this.location.prepareExternalUrl(this.location.path());
    }
}
