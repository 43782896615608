

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '../../app.constants';
import { ServiceBase } from './base/base.service';

@Injectable()
export class CategoryService extends ServiceBase {
    constructor(http: HttpClient, _configuration: Configuration) {
        super('category', http, _configuration)
    }
    public getMinMaxPosition<T>(): Observable<T> {
        return this.http.get<T>(`${this.actionUrl}${this.basePath}/minmax`);
    }
    public getByPos<T>(pos: number): Observable<T> {
        return this.http.get<T>(`${this.actionUrl}${this.basePath}/pos/${pos}`);
    }
    public uploadImg<T>(id, file) {
        const formData: any = new FormData();
        formData.append('uploads[]', file, file['name']);
        return this.http.post<T>(`${this.actionUrl}${this.basePath}/${id}/img`, formData);
    }
}
