import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';


declare var $: any;

@Component({
    selector: 'app-layout',
    templateUrl: './admin-layout.component.html'
})

export class AdminLayoutComponent implements OnInit {
    location: Location;
    private _router: Subscription;
    // url: string;

//     @ViewChild('sidebar') sidebar;
    constructor(private router: Router, location: Location) {
        this.location = location;
    }

    ngOnInit() {
/*
        const isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;
        if (isWindows) {
            // if we are on windows OS we activate the perfectScrollbar function
            const $main_panel = $('.main-panel');
            $main_panel.perfectScrollbar();
        }
*/

    }
}
