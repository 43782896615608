const ServerBase = 'https://dev.fenceshop.eu/';
// const ServerBase = 'http://54.37.9.192/';
const ApiUrl = 'api/';
const ClientApiUrl = 'clientapi/';

export const environment = {
    production: false,
    envName: 'remote_dev',
    Server: ServerBase,
    ApiUrl: ApiUrl,
    ClientApiUrl: ClientApiUrl,
    apiBase: ServerBase + ApiUrl,
    clientApiBase: ServerBase + ClientApiUrl
};
