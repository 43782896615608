

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from '../../app.constants';

@Injectable()
export class InvoiceService {

    private actionUrl: string;

    constructor(private http: HttpClient, private _configuration: Configuration) {
        this.actionUrl = _configuration.apiBase + 'invoice/';
    }

    public printInvoice<T>(model: any): Observable<T> {
        return this.http.post<T>(this.actionUrl + 'print?type=INVOICE', model);
    }
    public printOrder<T>(model: any): Observable<T> {
        return this.http.post<T>(this.actionUrl + 'print?type=RECEIPT', model);
    }
}