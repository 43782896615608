import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { Configuration } from "../../app.constants";
import { QueryStringBuilder } from "../utils/queryString";
import { ServiceBase } from "./base/base.service";

@Injectable()
export class OrderService extends ServiceBase {
  private actionUrlCart: string;
  constructor(http: HttpClient, _configuration: Configuration) {
    super("order", http, _configuration);
    this.actionUrlCart = _configuration.apiBase + "cart/";
  }

  public getAllStatus<T>(): Observable<T> {
    return this.http.get<T>(`${this.actionUrl}${this.basePath}/status`);
  }
  public getAllCarts<T>(queryString?): Observable<T> {
    const myParams = queryString
      ? QueryStringBuilder.BuildParametersFromSearch<T>(queryString)
      : new HttpParams();

    return this.http.get<T>(this.actionUrlCart, { params: myParams });
  }
  public updateStatus<T>(id: number, status): Observable<T> {
    return this.http.put<T>(
      `${this.actionUrl}${this.basePath}/${id}/status/${status}`,
      {}
    );
  }
  public updateOrderItem<T>(id: number, model: any): Observable<T> {
    return this.http.put<T>(
      `${this.actionUrl}${this.basePath}/orderitem/${id}`,
      model
    );
  }
  public generateNewOrderConfirm<T>(id: number): Observable<T> {
    return this.http.get<T>(
      `${this.actionUrl}${this.basePath}/orderconfirm/${id}`
    );
  }
  public generateNewCreditNoteAndInvoice<T>(id: number): Observable<T> {
    return this.http.get<T>(
      `${this.actionUrl}${this.basePath}/ordercreditnote/${id}`
    );
  }
  public generateBalance<T>(id: number): Observable<T> {
    return this.http.get<T>(`${this.actionUrl}${this.basePath}/balance/${id}`);
  }
  public deleteOrderItem<T>(id: number): Observable<T> {
    return this.http.delete<T>(
      `${this.actionUrl}${this.basePath}/orderitem/${id}`
    );
  }
  public createOrderItem<T>(model: any): Observable<T> {
    return this.http.post<T>(
      `${this.actionUrl}${this.basePath}/orderitem`,
      model
    );
  }
  public sendInvoice<T>(model: any): Observable<T> {
    return this.http.post<T>(
      `${this.actionUrl}${this.basePath}/sendinvoice`,
      model
    );
  }
  public sendNotifyFromStockout<T>(id: any): Observable<T> {
    return this.http.get<T>(
      `${this.actionUrl}${this.basePath}/${id}/send-notify-from-stockout`
    );
  }
}
