import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '../../app.constants';
import { ServiceBase } from './base/base.service';

@Injectable()
export class NotifyService extends ServiceBase {
    constructor(
        http: HttpClient,
        _configuration: Configuration
    ) {
        super('notify', http, _configuration)
    }
}
