

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Configuration } from '../../app.constants';
import { ServiceBase } from './base/base.service';

@Injectable()
export class BrandService extends ServiceBase {

    constructor(http: HttpClient, _configuration: Configuration) {
        super('brand', http, _configuration)
    }

    public uploadLogo<T>(id, file) {
        const formData: any = new FormData();
        formData.append('uploads[]', file, file['name']);

        return this.http.post<T>(`${this.actionUrl}${this.basePath}/${id}/logo`, formData);
    }
    public uploadPremium<T>(id, file) {
        const formData: any = new FormData();
        formData.append('uploads[]', file, file['name']);

        return this.http.post<T>(`${this.actionUrl}${this.basePath}/${id}/premium`, formData);
    }
    /*   public getSingle<T>(id: number): Observable<T> {
          return this.http.get<T>(this.actionUrl + id);
      }

       */
}
