import { HttpParams } from '@angular/common/http';


export class QueryStringBuilder {
    static BuildParametersFromSearch<T>(obj: T): HttpParams {
        let params: HttpParams = new HttpParams();

        if (obj == null) {
            return params;
        }

        Object.keys(obj).forEach((key) => {
            if (obj[key]) {
                params = params.append(key, obj[key]);
            }
        });

        return params;
    }
}
