import { NgModule } from '@angular/core';

import { OrderBy, StripHtml, CustomNumberPipe } from './pipes';

@NgModule({
    declarations: [OrderBy, StripHtml, CustomNumberPipe],
    exports: [OrderBy, StripHtml, CustomNumberPipe]
})

export class MainPipeModule {
}
