import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy',
    pure: false
})
export class OrderBy implements PipeTransform {
    tmp = [];
    static _orderByComparator(a: any, b: any): number {

        if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
            if (a.toLowerCase() < b.toLowerCase()) { return -1; }
            if (a.toLowerCase() > b.toLowerCase()) { return 1; }
        } else {
            if (parseFloat(a) < parseFloat(b)) { return -1; }
            if (parseFloat(a) > parseFloat(b)) { return 1; }
        }
        return 0;
    }


    transform(input: any, args): any {
        if (!Array.isArray(input)) {
            return input;
        }

        const desc = args.substr(0, 1) === '-';
        const property: string = args.substr(0, 1) === '+' || args.substr(0, 1) === '-'
            ? args.substr(1)
            : args;

        this.tmp = input.sort((a: any, b: any) => {
            return !desc ?
                OrderBy._orderByComparator(a[args], b[args])
                : -OrderBy._orderByComparator(a[args], b[args]);
        });

        return this.tmp;
    }

}
@Pipe({
    name: 'stripHtml',
    pure: false
})
export class StripHtml implements PipeTransform {
    tmp = '';
    transform(input: any, args): any {
        this.tmp = input ? String(input).replace(/(<([^>]+)>)/ig, '') : '';
        return this.tmp;
    }

}
@Pipe({
    name: 'customNumber'
})
export class CustomNumberPipe implements PipeTransform {
    transform(value: number): any {
        return (value).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
}
